.app__roadmap {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__announcements-container {
  width: 80%;
  margin-top: 3rem;
  margin: auto;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__announcements-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 5rem;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 100;
    margin: auto;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__announcements-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

.app__announcements-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__announcements-exp-item {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;

  @media screen and (max-width: 1500px) {
    width: 80%;
  }
}

.app__announcements-exp-works {
  flex: 1;

  .app__announcements-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 0.8rem;
    cursor: pointer;

    h4 {
      font-weight: 500;

      @media screen and (max-width: 800px) {
        font-weight: 450;
        font-size: 0.95rem;
      }

      @media screen and (max-width: 600px) {
        font-weight: 400;
        font-size: 0.8rem;
      }
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }
}

.app__announcements-exp-year {
  width: 10rem;
  display: flex;
  justify-content: center;
  margin-right: 3rem;
  background-color: #ffffff;
  border-radius: 0.8rem;
  padding: 1.5rem;

  @media screen and (max-width: 700px) {
    padding: 1rem;
    width: 8rem;
  }

  p {
    font-weight: 600;

    @media screen and (max-width: 700px) {
      font-size: 0.95rem;
    }
  }
}

.app_announcements-button {
  margin-top: 2rem;
  padding: 2.4rem 4.8rem;
  cursor: pointer;
  font-size: 1.45rem;
  border-radius: 1.3rem;
  border: none;
  font-weight: 500;
  background-color: #ffffff;
  color: var(--secondary-color);
  text-decoration: none;
  transition: all 0.7s ease-in-out;
  font-weight: bold;

  &:hover {
    background-color: var(--primary-color);
    color: #333;
  }

  @media screen and (max-width: 1500px) {
    font-size: 1.1rem;
  }
}

.announcements-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;
  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

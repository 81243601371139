.app__header__sponsors {
  flex: 1;
  height: 100%;
  flex-direction: row;
  padding: 7rem 2rem 0 !important;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (min-width: 450px) {
    padding-top: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }
  .badge-cmp {
    .wawe-emoji {
      font-size: 2rem;

      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
    div {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles-v2 {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 100%;

  .circle-cmp {
    margin: 3rem 8rem;
    
    @media screen and (max-width: 1200px) {
       margin: 2.5rem 3.5rem; 
    }
  }

  div {
    width: 150px;
    height: 150px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      width: 60%;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 100;
    border-radius: 2em;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2;
    z-index: 0;

    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}
